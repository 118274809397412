/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { reportFileTypes } from "../../../constants/common-constant";
import { TransactionReportTypeEnum } from "../../../constants/enums/transaction-report-type-enum";
import { useStore } from "../../../contexts/StoreProvider";
import { IOption } from "../../../models/ICommon";
import { IAddReport, IReportInfo } from "../../../models/response/IReportResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormDateTimePicker from "../../../shared-components/FormikFormHandlers/FormikFormDateTimePicker";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { transactionPaymentReportValidateSchema, transactionReportValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/label/label";
import UserType from "../../../constants/userType.constant";
import { getUserType } from "../../../helpers/localStorages.helper";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: IReportInfo;
  idLabel: string;
  transactionReportType: TransactionReportTypeEnum;
  label: string;
  IdOptions: IOption[];
}

const ReportsFilterForm = (props: IProps) => {
  const { preferencesStore } = useStore();
  const {
    initialValues,
    submitHandler,
    onModalClosed,
    actionInProgress,
    idLabel,
    transactionReportType,
    label,
    IdOptions
  } = props;


  const date = new Date();
  const startDate: Date = new Date(date.setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000);
  const endDate: Date = new Date(date.setHours(23, 59, 59, 999));
  const [dateFilter, setDateFilter] = useState<{ startDate: Date, endDate: Date }>({ startDate: startDate, endDate: endDate });
  const [isGlobalFilterChanged, setIsGlobalFilterChanged] = useState<boolean>(false);
  const [selectedSearchId, setSelectedSearchId] = useState<string>("");

  const FormObserver: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<IReportInfo>();

    useEffect(() => {
      if (values.SearchId !== selectedSearchId) {
        setSelectedSearchId(values.SearchId)
        setFieldValue("FileName", transactionReportType !== TransactionReportTypeEnum.PAYMENT_WISE ? values.SearchId : `${values.SearchId}_payment`);
      }
    }, [values.SearchId, setFieldValue]);
    return null;
  };

  return (
    <>
      <Modal className="add-user-modal" show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='generate_report' />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={(transactionReportType !== TransactionReportTypeEnum.PAYMENT_WISE) ? transactionReportValidateSchema : transactionPaymentReportValidateSchema}
          enableReinitialize
          onSubmit={async (values: any) => {
            let data: IAddReport = {
              SearchId: values.SearchId === "please_select" ? "" : (values.SearchId ? values.SearchId.trim() : values.SearchId),
              StartDate: values.StartDate ? moment(values.StartDate.$d as Date ? values.StartDate.$d : values.StartDate).utc().toISOString() : "",
              EndDate: values.EndDate ? moment(values.EndDate.$d as Date ? values.EndDate.$d : values.EndDate).utc().toISOString() : "",
              FileName: values.FileName ? values.FileName.trim() : values.FileName,
              ReportType: transactionReportType,
              ActionType: values.ActionType
            }
            let paymentData: IAddReport = {
              SearchId: values.SearchId,
              CustomerId: values.SearchId ? IdOptions.filter((option) => option.Name === values.SearchId)[0].Id : -1,
              StartDate: values.StartDate ? moment(values.StartDate.$d as Date ? values.StartDate.$d : values.StartDate).utc().toISOString() : "",
              EndDate: values.EndDate ? moment(values.EndDate.$d as Date ? values.EndDate.$d : values.EndDate).utc().toISOString() : "",
              FileName: values.FileName ? values.FileName.trim() : values.FileName,
              ReportType: transactionReportType,
              ActionType: values.ActionType
            }
            submitHandler((transactionReportType !== TransactionReportTypeEnum.PAYMENT_WISE) ? data : paymentData);
          }} >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>

                <Row>
                  {/* <Col xs={12} className="mb-3 alarm-datepicker">
                    <Label className="form-label mt-2 pt-1 mb-0"
                      label={formatMessage("")} />
                  </Col> */}
                  <Col xs={6} className="alarm-datepicker">
                    <div className="form-group datepicker-transaction">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_starttime"
                      />
                      {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TsGen"
                            as={FormikFormDateTimePicker}
                          /> */}
                      <Field
                        name="StartDate"
                        as={FormikFormDateTimePicker}
                        // label="From"
                        ampm="false"
                      // max={getCurrentDateTime()}
                      />
                    </div>

                    {/* <DateRangeFilter dateFilterHandler={setDateFilter} setIsGlobalFilterChanged={setIsGlobalFilterChanged} isGlobalFilterChanged={isGlobalFilterChanged} dateFilter={dateFilter} /> */}
                  </Col>
                  <Col xs={6} className="alarm-datepicker">
                    <div className="form-group datepicker-transaction">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_endtime"
                      />
                      {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TsGen"
                            as={FormikFormDateTimePicker}
                          /> */}
                      <Field
                        name="EndDate"
                        as={FormikFormDateTimePicker}
                        // label="From"
                        ampm="false"
                      // max={getCurrentDateTime()}
                      />
                    </div>

                    {/* <DateRangeFilter dateFilterHandler={setDateFilter} setIsGlobalFilterChanged={setIsGlobalFilterChanged} isGlobalFilterChanged={isGlobalFilterChanged} dateFilter={dateFilter} /> */}
                  </Col>

                  {<Col xs={12}>
                    <div className="form-group mb-3">
                      <Label
                        className="form-label"
                        label={transactionReportType !== TransactionReportTypeEnum.PAYMENT_WISE ? idLabel : "customer_id"}
                      />
                      <Field
                        data-testid="testSearchId"
                        className="form-control"
                        name="SearchId"
                        type="text"
                        invalidcharacter={[" "]}
                        validcharacter={["_"]}
                        placeholder={transactionReportType !== TransactionReportTypeEnum.PAYMENT_WISE ? idLabel : "customer_id"}
                        options={UserType.CustomerAdmin === getUserType() && transactionReportType === TransactionReportTypeEnum.PAYMENT_WISE ? IdOptions.filter(e => e.Id > 0) : IdOptions}
                        as={FormikFormSelectField}
                        isName={+true}
                      />
                    </div>
                  </Col>}

                  <Col xs={12}>
                    <div className="form-group mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="report_name"
                      />
                      <Field
                        data-testid="testFileName"
                        className="form-control"
                        name="FileName"
                        type="text"
                        validcharacter={["-", "_", "@", "#", "$", "."]}
                        placeholder="report_name"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="action_type"
                      />
                      <Field
                        className="form-control"
                        name="ActionType"
                        placeholder="action_type"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={reportFileTypes}
                      />
                    </div>
                  </Col>
                </Row>

              </Modal.Body>
              <div className="modal-footer border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text={"generate_" +
                      (values.ActionType == 0 ? "pdf" : (values.ActionType == 1 ? "excel" : "view"))}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal >
    </>
  );
};

export default ReportsFilterForm;
