/*    
<summary>
  This functional component "DashboardFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormDropdownTable from "../../../shared-components/FormikFormHandlers/FormikFormDropDownTable";
import { ErrorMessage } from "../../../constants/error.constant";
import {
  customerIdList,
  userTypeList,
} from "../../../constants/common-constant";
import { ITruckOption } from "../../../models/ICommon";
import NoDataFilter from "../../../assets/Images/no_filter-truck.png";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { dashboardFilterValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
  submitHandler: (values: ITruckOption) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: { TruckIds: string | string[] };
  truckOptions: ITruckOption[];
  title: string;
  singleSelector: boolean;
  disabled: boolean;
  selectAllColumns: any[]
}

const DashboardFilterForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    initialValues,
    actionInProgress,
    truckOptions,
    title,
    singleSelector,
    selectAllColumns,
    disabled
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<{ TruckIds: string | string[] }>();
    return null;
  };

  return (
    <>
      <Modal className="add-user-modal" show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id={title} />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          // validationSchema={dashboardFilterValidateSchema}
          onSubmit={async (values) => {
            const tempValue = truckOptions.filter((truck: ITruckOption) => truck.Id == values.TruckIds);
            submitHandler(tempValue[0]);
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>

              <FormObserver />
              {
                disabled ?
                  <>
                    <div className="No-data-filter text-center py-2">
                      <img src={NoDataFilter} alt="NOData" className="NODataFilter" />
                      <p className="h5 mt-3 pt-2 ">No Data Available</p>
                    </div>
                  </> :
                  <Modal.Body>
                    <Row className="mb-3 form-group d-flex align-items-center">
                      <Col xs={3}>
                        <Label
                          className="form-label"
                          label="truck_id"
                        />
                      </Col>
                      <Col xs={9}>
                        {singleSelector ?
                          <Field
                            name="TruckIds"
                            className="form-control"
                            as={FormikFormSelectField}
                            options={truckOptions}
                          />
                          : <Field
                            as={FormikFormDropdownTable}
                            data-testid="testTemplateId"
                            name='TruckIds'
                            data={truckOptions}
                            columns={selectAllColumns}
                            hiddenColumns={['Id']}
                            keyField='Id'
                            searchEnabled={true}
                            className="form-control"
                          />}
                      </Col>
                    </Row>

                  </Modal.Body>
              }
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    disabled={disabled || actionInProgress}
                    isLoading={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default DashboardFilterForm;
