/*    
<summary>
   This is the Customer Component which shows & manages the Customers Data.
   Developer: Aashish Singh, Created Date:20-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, {
  getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SearchField from "../../shared-components/SearchField/SearchField";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter } from "../../models/IFormatter";
import {
  PageLimitOptions,
  initialPageLimit,
} from "../../constants/pageLimit.constant";
import { Card, Container, Dropdown, ListGroup } from "react-bootstrap";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import { Col, Row } from "react-bootstrap";
import AddUpdateCustomer from "./components/add-edit-customer";
import AddUpdateCustomerSetting from "./components/add-edit-customer-setting";
import "./customer.scss";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import {
  ICustomerList,
  ICustomer,
} from "../../models/response/ICustomerResponse";
import CustomerFilterForm from "./forms/customer-filter-form";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import { getClientId, getUserType } from "../../helpers/localStorages.helper";
import UserType from "../../constants/userType.constant";
import { ModuleName } from "../../constants/moduleName.constant";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import DeleteConfirmPopup from "../../shared-components/confirmationPopup/confirm-popup";
import ConfirmPopupHOC from "../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc";
import NoData from "../../assets/Images/no_record.png";
import Pdf from "../../assets/Images/pdf.svg";
import { emptyValue, notAvailable } from "../../constants/common-constant";
import _ from "lodash";
import { customerInitialFilterState } from "../../core/initialState/initial-filter-states";
import UrlConstants from "../../constants/url.constant";
import EditSettingPopupHOC from "./hoc/EditSettingPopupHoc";

import AddInvoiceDetailsHoc from "./hoc/AddInvoiceDetailsHoc";
import AddInvoiceDetails from "./components/add-invoice-details";
import PaymentDetailsHoc from "./hoc/PaymentDetailsHOC";
import AddPaymentDetails from "./components/add-payment-details";

interface IProps {
  tabKey: string;
  setTabKey: (k: string) => void;
  size: {
    height: number;
  };
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
  showConfirmPopup: boolean;
  confirmToggleHandler: () => void;
  showEditSettingPopup: boolean;
  editSettingToggleHandler: () => void;
  showAddInvoiceDetailsPopup: boolean;
  addInvoiceDetailsToggleHandler: () => void;
  showPaymentDetailsPopup: boolean;
  paymentDetailsToggleHandler: () => void;
}

const Customer: React.FC<IProps> = (props: IProps) => {
  const { customerStore, preferencesStore, paymentStore } = useStore();
  const {
    tabKey,
    setTabKey,
    showAddPopup,
    addToggleHandler,
    showFilterPopup,
    filterToggleHandler,
    showConfirmPopup,
    confirmToggleHandler,
    showEditSettingPopup,
    editSettingToggleHandler,
    showAddInvoiceDetailsPopup,
    addInvoiceDetailsToggleHandler,
    showPaymentDetailsPopup,
    paymentDetailsToggleHandler,
  } = props;
  const {
    inProgress,
    error,
    customerList,
    GetCustomerListService,
    getCustomerList,
    reset,
    resetError,
    DeleteCustomerService,
    DeleteCustomerState,
    resetDeleteCustomerState,
    addUpdateCustomerState,
    downloadCustomerCSV,
    resetGetCustomerDetail,
    filter,
    setFilterDetail,
    totalCustomer
  } = customerStore;
  const { selectedClientId, setSelectedClientId, CSVFileDownloadPostService, allAvailableClientOptions, resetClientListState, clientList, setAmountDecimal } =
    preferencesStore;
  const { addPaymentDataState, deletePaymentDataState } = paymentStore;

  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<ICustomerList | undefined>(
    undefined
  );
  const [openPanel, setOpenPanel] = useState(false);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const customerListData: Array<ICustomerList> = getCustomerList;
  const [pageLimit, setPageLimit] = useState<number>(initialPageLimit);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "customer_name",
    orderBy: "asc",
  });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForUser = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPageLimit(parseInt(evt.target.value));
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  /**
   * This function provides filtering functionality of data on the basis of client id
   */
  const clientSelectHandler = (e: { label: string; value: number; key: number }) => {
    let value = e.value;
    if (value < 0) toast.error(formatMessage("please_select_any_client_admin"));
    else {
      resetFilterHandler();
      setSelectedClientId(value);
      setAmountDecimal(value)
      setPageInfo({ ...pageInfo, currentPage: 1 });
    }
  };

  /**
   * This function calls the "GetCustomerListService" store funtion that get customer list from API
   */
  const callGetCustomerList = useCallback(() => {
    setDisablePagination(true);
    if (getUserType() === UserType.SuperAdmin) {
      if (selectedClientId > 0 && clientList.length > 0) {
        GetCustomerListService(
          pageInfo.currentPage,
          pageLimit,
          sorting.orderBy,
          sorting.column,
          selectedClientId,
          _.omit(filter, ["isFilterActive"])
        );
      }
    }
    else {
      GetCustomerListService(
        pageInfo.currentPage,
        pageLimit,
        sorting.orderBy,
        sorting.column,
        selectedClientId,
        _.omit(filter, ["isFilterActive"])
      );
    }
    setIsApiCalled(true);
  }, [
    GetCustomerListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    searchText,
    selectedClientId,
    filter,
    clientList.length
  ]);

  useEffect(() => {
    return () => {
      resetFilterHandler();
      resetClientListState();
    };
  }, []);

  // useEffect(() => {
  //   if (selectedClientId > 0 && filter.isFilterActive) {
  //     if (getUserType() == UserType.SuperAdmin) {
  //       if (selectedClientId != -1) callGetCustomerList();
  //       else return;
  //     } else callGetCustomerList();
  //   }
  //   else {
  //     if (getUserType() == UserType.SuperAdmin) {
  //       if (selectedClientId != -1) callGetCustomerList();
  //       else return;
  //     } else callGetCustomerList();
  //   }
  // }, [filter, selectedClientId, callGetCustomerList]);

  useEffect(() => {
    if (selectedClientId > 0) { callGetCustomerList(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, selectedClientId, callGetCustomerList]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: customerList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getCustomerList, customerList.TotalPages]);

  useEffect(() => {
    if (addUpdateCustomerState.success) {
      callGetCustomerList();
    }
  }, [addUpdateCustomerState.success, callGetCustomerList]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  useEffect(() => {
    if (DeleteCustomerState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteCustomerState();
      confirmToggleHandler();
      callGetCustomerList();
    }
  }, [DeleteCustomerState.success, reset, callGetCustomerList]);

  useEffect(() => {
    if (DeleteCustomerState.error) {
      toast.error(formatMessage(DeleteCustomerState.error));
      resetDeleteCustomerState();
    }
  }, [DeleteCustomerState.error, resetDeleteCustomerState]);

  useEffect(() => {
    if (addPaymentDataState.success) {
      callGetCustomerList();
    }
  }, [addPaymentDataState.success, callGetCustomerList]);

  useEffect(() => {
    if (deletePaymentDataState.success) {
      callGetCustomerList();
    }
  }, [deletePaymentDataState.success, callGetCustomerList]);

  const filterSubmitHandler = (values: any) => {
    closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    // setFilterData({...values, isFilterActive: true});
    setFilterDetail({ ...values, isFilterActive: true });
    props.filterToggleHandler();
  };

  const resetFilterHandler = () => {
    // closePanelHandler();
    // setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...customerInitialFilterState });
    // setFilterData({
    //   ...clientInitialFilterState
    // });
  };

  /**
   * This function shows the add customer pop up from where we can add new customer and update existing customer. we use id to identify
   * that we are adding or updating customer.
   * "Id == -1" : we are adding new customer
   * "Id != -1" : means we are updating existing customer
   */
  const openAddUpdateHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    addToggleHandler();
  };

  const openUpdateSettingHandler = () => {
    closePanelHandler();
    setChangeDetails(false);
    editSettingToggleHandler();
  };

  const closeSettingHandler = () => {
    editSettingToggleHandler();
  };

  const closeInvoiceHandler = () => {
    addInvoiceDetailsToggleHandler();
    callGetCustomerList();
  };
  const closePaymentHandler = () => {
    paymentDetailsToggleHandler();
    // callGetCustomerList();
  };

  const openFilterHandler = () => {
    filterToggleHandler();
  };

  /**
   * This function shows the delete customer confirmation popup component and sets the seleted customer Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    confirmToggleHandler();
  };
  const addInvoiceClickHandler = (row: { Id: number; Name: string }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setSelectedCustomerName(row.Name);
    setChangeDetails(false);
    addInvoiceDetailsToggleHandler();
  };
  const paymentClickHandler = (row: { Id: number; Name: string }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setSelectedCustomerName(row.Name);
    setChangeDetails(false);
    paymentDetailsToggleHandler();
  };

  /**
   * This function deletes the customer by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteCustomerService(selectedId);
  };

  /**
   * This function shows the detail customer panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const exportFilteredLogsCSV = () => {
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.CustomerCSVDownload}?clientId=${clientIdFromLocalStorage}`;
    let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    tempFilterDetails = {
      ...tempFilterDetails,
      IsActive:
        filter.IsActive == "2" ? null : filter.IsActive == "1" ? false : true,
    };
    CSVFileDownloadPostService(url, "Customer", tempFilterDetails);
  };

  const rowEvents = (row: any) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelHandler = () => {
    setSelectedRow(undefined);
    setOpenPanel(false);
    resetGetCustomerDetail();
  };

  const addIcons: Array<IActionFormatter<string>> = [
    {
      handler: openAddUpdateHandler,
      icon: (
        <svg height="14" viewBox="0 0 492.49284 492" width="14">
          <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
          <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
        </svg>
      ),
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning",
    },
    {
      handler: deleteClickHandler,
      icon: (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="14"
          height="14"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      ),
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
      className: "btn-danger",
    },
    {
      handler: rowEvents,
      icon: (
        <svg
          enableBackground="new 0 0 519.643 519.643"
          width="14"
          height="14"
          viewBox="0 0 519.643 519.643"
        >
          <circle cx="259.823" cy="259.866" r="80" />
          <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
        </svg>
      ),
      key: "",
      title: "Label_ShowDetails",
      className: "btn-secondary",
    },
  ];

  const columns = getColumns<ICustomer, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "Id",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustomerId",
        sortingName: "customer_id",
        displayName: "customer_Id",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Name",
        sortingName: "customer_name",
        displayName: "customer_name",
      },
      {
        columnType: ColumnType.ActionsFormatter,
        icons: addIcons,
        displayName: "label_action",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "PhoneNo",
        sortingName: "PhoneNo",
        displayName: "phone_no",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "ContactPersonName",
        sortingName: "ContactPersonName",
        displayName: "table_label_contact_person_name",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "ContactPersonPhoneNo",
        sortingName: "ContactPersonPhoneNo",
        displayName: "table_lable_contact_person_phone_no",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "ContactPersonEmail",
        sortingName: "ContactPersonEmail",
        displayName: "table_lable_contact_person_email",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Address",
        sortingName: "address",
        displayName: "user_label_address",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "City",
        sortingName: "city",
        displayName: "user_label_city",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "State",
        sortingName: "state",
        displayName: "user_label_state",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Country",
        sortingName: "country",
        displayName: "user_label_country",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "IsActive",
        displayName: "is_active",
      },
    ],
    sorting,
    handleSortingChange
  );

  return (
    <React.Fragment>
      {showAddPopup && (
        <AddUpdateCustomer id={selectedId} modalClosed={addToggleHandler} />
      )}
      {showEditSettingPopup && (
        <AddUpdateCustomerSetting modalClosed={closeSettingHandler} />
      )}
      {showAddInvoiceDetailsPopup && (
        <AddInvoiceDetails
          id={selectedId}
          modalClosed={closeInvoiceHandler}
          selectedCustomer={selectedCustomerName}
        />
      )}
      {showPaymentDetailsPopup && (
        <AddPaymentDetails
          id={selectedId}
          modalClosed={closePaymentHandler}
          selectedCustomer={selectedCustomerName}
        />
      )}
      {showFilterPopup && (
        <CustomerFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )}

      {showConfirmPopup && (
        <DeleteConfirmPopup
          title="delete_customer"
          modalSubmit={deleteHandler}
          modalClosed={confirmToggleHandler}
          isLoading={DeleteCustomerState.inProgress}
          message="delete_customer_confirm"
          dialogClassName="delete_popup"
        />
      )}

      <div className={`main-content user`}>
        <SubHeader
          moduleNameString={"customer_title"}
          moduleName={ModuleName.Customer}
          exportCSVHandler={exportFilteredLogsCSV}
          filterHandler={openFilterHandler}
          addHandler={openAddUpdateHandler}
          isAdd={getUserType() == UserType.SuperAdmin
            ? selectedClientId > 0
              ? true
              : false
            : true}
          addButtonTitle="add_customer"
          showClientSelectHandler={true}
          clientSelectHandler={clientSelectHandler}
          isAddSetting={true}
          addSettingHandler={openUpdateSettingHandler}
          isFullView={false}
          isDownload={true}
          isRefresh={false}
          isFilter={true}
          isDateFilter={false}
          isFilterActive={filter.isFilterActive}
          resetFilterHandler={resetFilterHandler} isImport={false} />
      </div>
      {inProgress && <SpinnerLoader />}
      <Container fluid className="p-0">
        {customerListData.length !== 0 ? (
          <div className="customerWrapper background-image">
            <div className="py-3 px-3">
              <div
                className={`${customerListData.length > 0 ? "blank_height" : ""
                  }`}
              >
                <Row>
                  <div className="Total-users d-flex justify-content-end mb-3">
                    <FormattedMessage id="Total Customers" /> : {" "} <b className="ps-1"> {totalCustomer}</b>
                  </div>
                  {customerListData.map((customer: ICustomerList) => {
                    return (
                      <Col lg={3} md={4} className="mb-4" key={customer.Id}>
                        <Card className="shadow-sm rounded b-0 profile-widget p-0 h-100">
                          <div className="customer-user h-100">
                            <div className="d-flex justify-content-end edit-popup">
                              <Dropdown className="border-0 enable-caret editButton">
                                <Dropdown.Toggle
                                  variant=""
                                  id="dropdown-basic"
                                  className="shadow-none p-0 ViewAll pe-3"
                                >
                                  <span>
                                    {" "}
                                    <i className="fas fa-ellipsis-v"></i>
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-start p-0">
                                  {/* <Dropdown.Item
                                  className="d-flex align-items-center pb-0"
                                  onClick={() => {
                                    openUpdateSettingHandler(customer);
                                  }}
                                >
                                  <div className="pdf-icon">
                                 <img src={Pdf} alt="pdf"/>
                                 </div>                                 
                                  <div className="fs-6 text-white">
                                    <a
                                      className="dropdown-item  p-0 pt-1"
                                      href="javascript:;"
                                    >
                                      <FormattedMessage id="label_setting_edit" />
                                    </a>
                                  </div>
                                </Dropdown.Item> */}
                                  <Dropdown.Item
                                    className="d-flex align-items-center pb-0"
                                    onClick={() => {
                                      openAddUpdateHandler({ Id: customer.Id });
                                    }}
                                    title="Edit"
                                  >
                                    <div className="icon-svg-dots payment-icon">
                                      <svg
                                        height="14"
                                        viewBox="0 0 492.49284 492"
                                        width="14"
                                        fill="#fff"
                                      >
                                        <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
                                        <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
                                      </svg>
                                    </div>
                                    <div className="ps-2 fs-6 text-white">
                                      <a
                                        className="dropdown-item  p-0 pt-1"
                                        href="javascript:;"
                                      >
                                        <FormattedMessage id="label_edit" />
                                      </a>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="d-flex align-items-center pb-0"
                                    onClick={() => {
                                      addInvoiceClickHandler({
                                        Id: customer.Id,
                                        Name: customer.Name,
                                      });
                                    }}
                                    title="Pricing"
                                  >
                                    <div className="icon-svg-dots payment-icon">
                                      <svg
                                        height="18px"
                                        viewBox="-21 -21 682 682.66669"
                                        width="18px"
                                        fill="#fff"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="m274.667969 640c-.015625 0-.035157 0-.054688 0-20.027343-.015625-38.859375-7.828125-53.015625-22.007812l-199.390625-199.675782c-29.164062-29.21875-29.164062-76.761718 0-105.976562l268.78125-269.296875c27.699219-27.753907 64.554688-43.042969 103.773438-43.042969h170.527343c41.351563 0 75 33.640625 75 75v170.003906c0 39.191406-15.273437 76.03125-43 103.726563l-269.617187 269.335937c-14.164063 14.144532-32.988281 21.933594-53.003906 21.933594zm120.09375-590c-25.84375 0-50.128907 10.074219-68.382813 28.363281l-268.785156 269.296875c-9.722656 9.742188-9.722656 25.585938 0 35.328125l199.390625 199.675781c4.714844 4.722657 10.988281 7.332032 17.667969 7.335938h.019531c6.671875 0 12.945313-2.597656 17.664063-7.308594l269.617187-269.332031c18.273437-18.25 28.335937-42.53125 28.335937-68.355469v-170.003906c0-13.785156-11.214843-25-25-25zm64.273437 203.75c-41.355468 0-75-33.640625-75-75s33.644532-75 75-75c41.355469 0 75 33.640625 75 75s-33.644531 75-75 75zm0-100c-13.785156 0-25 11.214844-25 25s11.214844 25 25 25c13.789063 0 25-11.214844 25-25s-11.210937-25-25-25zm0 0" />
                                      </svg>
                                    </div>
                                    <div className="ps-2 fs-6 text-white">
                                      <a
                                        className="dropdown-item  p-0 pt-1"
                                        href="javascript:;"
                                      >
                                        <FormattedMessage id="pricing_details" />
                                      </a>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="d-flex align-items-center pb-0"
                                    onClick={() => {
                                      paymentClickHandler({
                                        Id: customer.Id,
                                        Name: customer.Name,
                                      });
                                    }}
                                    title="Payment"
                                  >
                                    <div className="icon-svg-dots payment-icon">
                                      <svg
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 24 24"
                                        fill=""
                                        xmlns="
http://www.w3.org/2000/svg"
                                        style={{ fill: "white" }}
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V6.31673C14.3804 6.60867 15.75 7.83361 15.75 9.5C15.75 9.91421 15.4142 10.25 15 10.25C14.5858 10.25 14.25 9.91421 14.25 9.5C14.25 8.82154 13.6859 8.10339 12.75 7.84748V11.3167C14.3804 11.6087 15.75 12.8336 15.75 14.5C15.75 16.1664 14.3804 17.3913 12.75 17.6833V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.6833C9.61957 17.3913 8.25 16.1664 8.25 14.5C8.25 14.0858 8.58579 13.75 9 13.75C9.41421 13.75 9.75 14.0858 9.75 14.5C9.75 15.1785 10.3141 15.8966 11.25 16.1525V12.6833C9.61957 12.3913 8.25 11.1664 8.25 9.5C8.25 7.83361 9.61957 6.60867 11.25 6.31673V6C11.25 5.58579 11.5858 5.25 12 5.25ZM11.25 7.84748C10.3141 8.10339 9.75 8.82154 9.75 9.5C9.75 10.1785 10.3141 10.8966 11.25 11.1525V7.84748ZM12.75 12.8475V16.1525C13.6859 15.8966 14.25 15.1785 14.25 14.5C14.25 13.8215 13.6859 13.1034 12.75 12.8475Z"
                                          fill=""
                                        ></path>
                                      </svg>
                                    </div>
                                    <div className="ps-2 fs-6 text-white">
                                      <a
                                        className="dropdown-item  p-0 pt-1"
                                        href="javascript:;"
                                      >
                                        <FormattedMessage id="payment_details" />
                                      </a>
                                    </div>
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    className="d-flex align-items-center"
                                    onClick={() => {
                                      deleteClickHandler({ Id: customer.Id });
                                    }}
                                    title="Delete"
                                  >
                                    <div className="icon-svg-dots payment-icon">
                                      <svg
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="13"
                                        height="13"
                                        fill="#fff"
                                        viewBox="0 0 512 512"
                                      >
                                        <g>
                                          <g>
                                            <path
                                              d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
  L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
  c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
  c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
  c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
  c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
                                            />
                                          </g>
                                        </g>
                                        <g>
                                          <g>
                                            <path
                                              d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
  c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
  c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
                                            />
                                          </g>
                                        </g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                      </svg>
                                    </div>
                                    <div className="ps-2 fs-6 text-white">
                                      <a
                                        className="dropdown-item  p-0 pt-1"
                                        href="javascript:;"
                                      >
                                        <FormattedMessage id="label_delete" />
                                      </a>
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                            <div className="user-content align-items-center text-center">
                              <h4 className="user-name fw-semi-bold text-break">
                                {customer.Name}
                              </h4>
                              {customer.IsActive ? (
                                <span className="badge badge-pill active">
                                  <FormattedMessage id="active" />
                                </span>
                              ) : (
                                <span className="badge badge-pill ms-3 inactive">
                                  <FormattedMessage id="inactive" />
                                </span>
                              )}
                            </div>
                            <div className="list-group">
                              <div className="list-group-item border-0 bg-transparent">
                                <Row>
                                  <Col lg={12}>
                                    <div className="d-flex align-items-center p-2">
                                      <div className="timeline-circle green">
                                        <span className="material-symbols-outlined text-white">
                                          call
                                        </span>
                                      </div>
                                      <div className="ps-3">
                                        <h6 className="text-muted mb-0">
                                          <FormattedMessage id="contact_person_phone_no" />
                                          :
                                        </h6>
                                        <p className=" text-break fw-semi-bold mb-0">
                                          {customer.PhoneNo ? (
                                            <> {customer.PhoneNo}</>
                                          ) : (
                                            formatMessage(notAvailable)
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </Col>

                                  <Col lg={12}>
                                    <div className="d-flex justify-content-start px-2">
                                      <div>
                                        <div className="timeline-circle blue">
                                          <span className="material-symbols-outlined text-white">
                                            location_on
                                          </span>
                                        </div>
                                      </div>
                                      <div className="ps-3">
                                        <h6 className="text-muted mb-0">
                                          <FormattedMessage id="address" /> :
                                        </h6>
                                        <p className="text-break fw-semi-bold address ellipse-address mb-0">
                                          {customer.Address ||
                                            customer.City ||
                                            customer.State ||
                                            customer.Country ? (
                                            <>
                                              {customer.Address &&
                                                `${customer.Address}, `}
                                              {customer.City &&
                                                `${customer.City}, `}
                                              {customer.State &&
                                                `${customer.State}, `}
                                              {customer.Country}
                                            </>
                                          ) : (
                                            formatMessage(notAvailable)
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>

                          <div className="bg-light row g-0">
                            <p className="fw-semi-bold mb-2">
                              <FormattedMessage id="pricing_details" />{" "}
                              <span className="mb-1 text-break fw-semi-bold">
                                (
                                {customer.CurrencyName
                                  ? customer.CurrencyName
                                  : emptyValue}
                                )
                              </span>
                            </p>
                            <div className="col-6">
                              <small className="text-muted">
                                <FormattedMessage id="transactions_label_perunitgallon" />
                              </small>
                              <p className="mb-1 fw-semi-bold">
                                {customer.PricePerGallon !== null
                                  ? customer.PricePerGallon
                                  : emptyValue}
                              </p>
                            </div>
                            <div className="col-6">
                              <small className="text-muted">
                                <FormattedMessage id="transactions_label_perunitprice" />
                              </small>
                              <p className="mb-1 fw-semi-bold">
                                {customer.PricePerL !== null
                                  ? customer.PricePerL
                                  : emptyValue}
                              </p>
                            </div>
                            <div className="col-6">
                              <small className="text-muted text-break">
                                <FormattedMessage id="transactions_label_vat_percent" />
                              </small>
                              <p className="mb-1 text-break fw-semi-bold ">
                                {" "}
                                {customer.Vat !== null
                                  ? customer.Vat
                                  : emptyValue}{" "}
                              </p>
                            </div>

                            <div className="col-6">
                              <small className="text-muted">
                                <FormattedMessage id="amount_limit" />
                              </small>
                              <p className="mb-1 text-break fw-semi-bold">
                                {customer.MaxAmountLimit !== null
                                  ? customer.MaxAmountLimit
                                  : emptyValue}
                              </p>
                            </div>

                            <div className="col-6">
                              <small className="text-muted">
                                <FormattedMessage id="max_volume_limit_unit" />
                              </small>
                              <p className="mb-1 text-break fw-semi-bold">
                                {customer.MaxVolumeLimit !== null
                                  ? customer.MaxVolumeLimit
                                  : emptyValue}
                              </p>
                            </div>

                            <div className="col-6">
                              <small className="text-muted">
                                <FormattedMessage id="remaining_volume_l" />
                              </small>
                              <p className="mb-1 text-break fw-semi-bold">
                                {customer.RemainingVolume !== null
                                  ? customer.RemainingVolume
                                  : emptyValue}
                              </p>
                            </div>

                            {/* <div className="col-6">
                              <small className="text-muted">
                                <FormattedMessage id="threshold_volume_limit" />
                              </small>
                              <p className="mb-1 text-break fw-semi-bold">
                                {customer.ThresholdVolumeLimit !== null
                                  ? customer.ThresholdVolumeLimit
                                  : emptyValue}
                              </p>
                            </div> */}

                            {/* <div className="col-5">
                              <small className="text-muted"><FormattedMessage id="currency" /></small>
                              <p className="mb-1 text-break fw-semi-bold">{customer.CurrencyName ? customer.CurrencyName : emptyValue}</p>
                            </div> */}
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
                {/* <div className={`${openPanel ? "paneIsOpen" : ""}`}>
          <div className=" rounded shadow">
            <div className="Fuel-table mt-2">
              <div
                className="table-responsive"
                style={getTableMaxHeight(height, width)}
                data-testid="table"
              >
                {customerListData && (
                  <Grid
                    data={customerListData}
                    columns={columns}
                    isInProgress={inProgress}
                    hiddenColumns={["CustomerId"]}
                  />
                )}
              </div>
            </div>
            <Container fluid className="p-0">
              <Row className="tableFooter py-1">
                <Col>
                  <small>
                    <FormattedMessage id="show" />
                  </small>
                  <select
                    data-testid="pageSelect"
                    className="ms-2 from-control"
                    onChange={onPageLimitChangedForUser}
                    value={pageLimit}
                  >
                    {PageLimitOptions.map((op) => (
                      <option key={op} value={op}>
                        {op}
                      </option>
                    ))}
                  </select>
                </Col>

                <Col className="d-flex justify-content-end">
                  <Pagination
                    totalPages={pageInfo.totalPages}
                    pageLimit={pageLimit}
                    onPageChanged={onPageChanged}
                    disablePagination={disablePagination}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          </div> */}
                {/* <SlidingPanel
        type={"right"}
        isOpen={openPanel}
        noBackdrop={false}
        size={100}
      >
        <div>
          <div className="row border-bottom pt-3 ps-3">
            <div className="col-lg-10">
            <h2 className="fs-4 fw-bolder">  {selectedRow  ? selectedRow.UserName : ""}</h2>
            </div>
            <div className="col-lg-2 text-end">
              <svg
                onClick={closePanelHandler}
                role="button"
                xlinkTitle="close"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
          <div className="group_detailsWrapper px-3">
            <CustomerDetails selectedData={selectedRow} />
          </div>
        </div>
      </SlidingPanel> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="no_data_table background-image ">
            <div className="no_data w-100 text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default PaymentDetailsHoc(
  AddInvoiceDetailsHoc(
    EditSettingPopupHOC(
      ConfirmPopupHOC(
        FilterPopupHOC(
          EditPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Customer))))
        )
      )
    )
  )
);
