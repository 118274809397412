/*    
<summary>
   This is the Role Component which shows the Roles Data.
   Developer: Aashish Singh, Created Date:02-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, {
  getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SearchField from "../../shared-components/SearchField/SearchField";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter, IFormatter } from "../../models/IFormatter";
import Header from "../../shared-components/Header/Header";
import {
  PageLimitOptions,
  initialPageLimit,
} from "../../constants/pageLimit.constant";
import { Button, Container } from "react-bootstrap";
import DeletePopup from "../../shared-components/Popup/DeletePopup";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import { Col, ListGroup, Row } from "react-bootstrap";
import { IRole, IRoleList } from "../../models/response/IRoleResponse";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import FilterPopupHOC from "../../shared-components/Popup/Hoc/FilterPopupHoc";
import { ModuleName } from "../../constants/moduleName.constant";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import "./role.scss";
import AddUpdateRole from "./component/add-edit-role";
import { getClientId, getUserType } from "../../helpers/localStorages.helper";
import UserType from "../../constants/userType.constant";
import NoData from "../../assets/Images/no_record.png";
import UrlConstants from "../../constants/url.constant";
import userPageLimitSettingEnum from "../../constants/enums/user-pagination-setting-enum";
interface IProps {
  tabKey: string;
  setTabKey: (k: string) => void;
  size: {
    height: number;
  };
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
}

const Role: React.FC<IProps> = (props: IProps) => {
  const { roleStore, preferencesStore } = useStore();
  const {
    showDeletePopup,
    deleteToggleHandler,
    showAddPopup,
    addToggleHandler,
    showFilterPopup,
    filterToggleHandler,
  } = props;
  const {
    inProgress, error, roleList,
    GetRoleListService, getRoleList, reset,
    resetError, DeleteRoleService,
    DeleteRoleState, resetDeleteRoleState,
    addUpdateRoleState,
  } = roleStore;

  const { selectedClientId, setSelectedClientId, CSVFileDownloadService, userPageLimitSetting, setAmountDecimal, setUserPageLimitSetting, allAvailableClientOptions, resetClientListState, clientList } = preferencesStore;
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const roleListData: Array<IRoleList> = getRoleList;
  const [pageLimit, setPageLimit] = useState<number>(userPageLimitSetting.roleLimitConfig);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "UserName",
    orderBy: "asc",
  });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForUser = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimit(value);
    setUserPageLimitSetting(userPageLimitSettingEnum.RoleLimitConfig, value)
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  /**
   * This function calls the "GetRoleListService" store funtion that get role list from API
   */
  const callGetRoleList = useCallback(() => {
    setDisablePagination(true);
    if (getUserType() === UserType.SuperAdmin) {
      if (selectedClientId > 0 && clientList.length > 0) {
        GetRoleListService(
          pageInfo.currentPage,
          pageLimit,
          sorting.orderBy,
          sorting.column,
          searchText
        );
      }
    }
    else {
      GetRoleListService(
        pageInfo.currentPage,
        pageLimit,
        sorting.orderBy,
        sorting.column,
        searchText
      );
    }
    setIsApiCalled(true);
  }, [
    GetRoleListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    searchText,
    selectedClientId,
    clientList.length
  ]);

  useEffect(() => {
    if (selectedClientId) {
      if (getUserType() == UserType.SuperAdmin) {
        if (selectedClientId != -1)
          callGetRoleList();
        else
          return;
      }
      else
        callGetRoleList();
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (isApiCalled) {
      // showUserDetailToggleHandler();
      setIsApiCalled(false);
    }
  }, [isApiCalled]);

  useEffect(() => {
    return () => {
      resetClientListState();
    }
  }, []);
  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: roleList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getRoleList, roleList.TotalPages]);

  useEffect(() => {
    if (addUpdateRoleState.success) {
      callGetRoleList();
    }
  }, [addUpdateRoleState.success, callGetRoleList]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  useEffect(() => {
    if (DeleteRoleState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteRoleState();
      deleteToggleHandler();
      callGetRoleList();
    }
  }, [DeleteRoleState.success, reset, callGetRoleList]);

  useEffect(() => {
    if (DeleteRoleState.error) {
      toast.error(formatMessage(DeleteRoleState.error));
      resetDeleteRoleState();
    }
  }, [DeleteRoleState.error, resetDeleteRoleState]);

  /**
   * This function shows the add role pop up from where we can add new role and update existing role. we use id to identify
   * that we are adding or updating role.
   * "Id == -1" : we are adding new role
   * "Id != -1" : means we are updating existing role
   */
  const openAddUpdateHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(false);
    addToggleHandler();
  };

  const openFilterHandler = () => {
    filterToggleHandler();
  };

  const exportFilteredLogsCSV = () => {
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const requestUrl = `${UrlConstants.CSVExportAllRole}?clientId=${clientIdFromLocalStorage}`;
    CSVFileDownloadService(requestUrl, "Role");
  };

  /**
   * This function shows the delete role confirmation popup component and sets the seleted role Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(false);
    deleteToggleHandler();
  };

  /**
   * This function deletes the role by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteRoleService(selectedId);
  };

  /**
   * This function shows the detail role panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  /**
  * This function provides filtering functionality of data on the basis of client id
  */
  const clientSelectHandler = (e: { label: string; value: number; key: number }) => {
    let value = e.value;
    if (value < 0)
      toast.error(formatMessage("please_select_any_client_admin"));
    else {
      setSelectedClientId(value);
      setPageInfo({ ...pageInfo, currentPage: 1 });
      setAmountDecimal(value)
    }
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const addIcons: Array<IActionFormatter<ReactElement>> = [
    {
      handler: openAddUpdateHandler,
      icon: <svg height="14" viewBox="0 0 492.49284 492" width="14">
        <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
        <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
      </svg>,
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning"
    },
    {
      handler: deleteClickHandler,
      icon: <svg
        version="1.1"
        x="0px"
        y="0px"
        width="13"
        height="13"
        viewBox="0 0 512 512"
      >
        <g>
          <g>
            <path
              d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
    L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
    c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
    c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
    c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
    c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
    c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
    c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>,
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
    },
  ];

  const columns = getColumns<IRole, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "Id",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "RoleName",
        sortingName: "RoleName",
        displayName: "role_name",
      },
      {
        columnType: ColumnType.ActionsFormatter,
        icons: addIcons,
        displayName: "label_action",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "DriverManagementPermission",
        sortingName: "DriverManagementPermission",
        displayName: "driver_management_permission",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "VehicleManagementPermission",
        sortingName: "VehicleManagementPermission",
        displayName: "vehicle_management_permission",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "TankManagementPermission",
        sortingName: "TankManagementPermission",
        displayName: "tank_management_permission",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "TicketManagementPermission",
        sortingName: "TicketManagementPermission",
        displayName: "ticket_management_permission",
      },
    ],
    sorting,
    handleSortingChange
  );

  return (
    <React.Fragment>
      {showAddPopup && (
        <AddUpdateRole id={selectedId} modalClosed={addToggleHandler} />
      )}
      {/* {showFilterPopup && (
        <RoleFilterForm
          initialValues={{ "sgh": "vghj" }}
          submitHandler={() => { }}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )} */}
      {showDeletePopup && (
        <DeletePopup
          modalSubmit={deleteHandler}
          modalClosed={deleteToggleHandler}
          isLoading={DeleteRoleState.inProgress}
          message="delete_role_confirm"
          submitBtnText="submit_button"
          cancelBtnText="button_cancel"
          modalTitle="delete_role"
        />
      )}

      <div className={`main-content user`}>
        <SubHeader moduleNameString={"role_title"} moduleName={ModuleName.Roles}

          addHandler={openAddUpdateHandler} clientSelectHandler={clientSelectHandler}
          showClientSelectHandler={true}
          filterHandler={openFilterHandler} isAdd={true} addButtonTitle="add_role"
          exportCSVHandler={exportFilteredLogsCSV} isFullView={false} isDownload={true} isRefresh={false}
          isFilter={false} isDateFilter={false} isImport={false} />
      </div>

      {inProgress && <SpinnerLoader />}

      <Container fluid className="p-0">
        {roleListData.length !== 0 ? (
          <div className="roleWrapper background-image ">
            <div className="pt-3 px-3">
              <div className="rounded shadow">
                <div className="Fuel-table">
                  <div
                    className="table-responsive"
                    style={getTableMaxHeight(height, width)}
                    data-testid="table"
                  >
                    {roleListData && (
                      <Grid
                        data={roleListData}
                        columns={columns}
                        isInProgress={inProgress}
                        hiddenColumns={['Id']}
                      />
                    )}
                  </div>
                </div>

                <Container fluid>
                  <Row className="tableFooter py-1">
                    <Col xs="3" className="d-flex">
                      <b className="text-white text-hidden-m mt-1 pt-1">
                        <FormattedMessage id="show" />:{" "}
                      </b>
                      <select
                        data-testid="pageSelect"
                        className="ms-2 from-control"
                        onChange={onPageLimitChangedForUser}
                        value={pageLimit}
                      >
                        {PageLimitOptions.map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col className="d-flex justify-content-end" xs="9" >
                      <Pagination
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div className="no_data_table background-image">
            <div className="no_data w-100 text-capitalize text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="text-capitalize fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>
        )}  </Container>

      <Footer />
    </React.Fragment>
  );
};

export default FilterPopupHOC(EditPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Role)))));
