/*    
<summary>
   This class component is all about Managing common functionalities (i.e. language, version).
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import axiosDownload, { AxiosResponse } from "axios";
import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { toast } from "react-toastify";
import { DATE_TIME_FORMAT_FILENAME } from "../../constants/common-constant";
import { ErrorMessage } from "../../constants/error.constant";
import UrlConstants from "../../constants/url.constant";
import UserType from "../../constants/userType.constant";
import { getClientId, getCustomerId, getToken, getUserType } from "../../helpers/localStorages.helper";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IClient } from "../../models/response/IClientResponse";
import { IChangePasswordState, ICommonState } from "../../models/state/ICommonState";
import { IPreferenceState } from "../../models/state/IPreferenceState";
import { formatMessage } from "../../translations/formatMessage";
import * as services from '../service/base-service';

export class PreferencesStore implements ICommonState, IPreferenceState {
    inProgress = false;
    error = '';
    tableView = localStorage.getItem('secure_fuel_tableView') !== null ? localStorage.getItem('secure_fuel_tableView') : 'false';
    userPageLimitSetting: any = {
        trasnactionLimitConfig: 25,
        vehicleLimitConfig: 25,
        driverLimitConfig: 25,
        tankLimitConfig: 25,
        alarmLimitConfig: 25,
        roleLimitConfig: 25,
        clientLimitConfig: 25,
    };

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    clientList: Array<IClient> = [];
    selectedClientId: number = -1;
    amountDecimalValue: number = -1;

    isUserExistsState = { ...this.initialStateValue }

    isUserExists: boolean = false;

    isOTPVerifiedState = { ...this.initialStateValue }
    isOTPVerified: boolean | undefined = false;
    oneTimePassword: number | undefined = undefined;

    isPasswordUpdatedState = { ...this.initialStateValue }

    csvDownloadState: IObservableInitialState = { ...this.initialStateValue };

    selectedSuperAdminDashboardClientName: string = "please_select";

    truckSummaryState: IObservableInitialState = { ...this.initialStateValue }

    vehicleSummaryState: IObservableInitialState = { ...this.initialStateValue }

    customerSummaryState: IObservableInitialState = { ...this.initialStateValue }

    departmentSummaryState: IObservableInitialState = { ...this.initialStateValue }

    truckSummaryDetails: IOption[] = [];
    customerSummaryDetails: IOption[] = [];
    departmentSummaryDetails: IOption[] = [];
    vehicleSummaryDetails: IOption[] = [];

    constructor() {
        makeObservable(this, {
            userPageLimitSetting: observable,
            tableView: observable,
            clientList: observable,
            selectedClientId: observable,
            isUserExists: observable,
            isUserExistsState: observable,
            isOTPVerifiedState: observable,
            isOTPVerified: observable,
            oneTimePassword: observable,
            isPasswordUpdatedState: observable,
            csvDownloadState: observable,
            selectedSuperAdminDashboardClientName: observable,
            departmentSummaryState: observable,
            customerSummaryState: observable,
            truckSummaryState: observable,
            vehicleSummaryState: observable,
            truckSummaryDetails: observable,
            customerSummaryDetails: observable,
            departmentSummaryDetails: observable,
            vehicleSummaryDetails: observable,
            amountDecimalValue: observable,
            CSVFileDownloadService: action,
            CSVFileDownloadPostService: action,
            tableViewData: computed,
            GetAllClientsService: action,
            VerifyOTPPasswordResetService: action,
            VerifyUserPasswordResetService: action,
            UpdateUserPasswordByOTPService: action,
            setUpdateTableView: action,
            setUserPageLimitSetting: action,
            setSelectedClientId: action,
            setSelectedSuperAdminDashboardClientName: action,
            resetIsOTOVerified: action,
            resetIsUserCheck: action,
            resetUpdatePasswordState: action,
            resetStore: action,
            GetTruckSummaryService: action,
            GetVehicleSummaryService: action,
            GetCustomerSummaryService: action,
            GetDepartmentSummaryService: action,
            resetClientListState: action,
            setAmountDecimal: action,
            allAvailableClientOptions: computed,
            allAvailableClientsOptions: computed,
            getTruckOptions: computed,
            getVehicleOptions: computed,
            getCustomerOptions: computed,
            getDepartmentOptions: computed,
            getDepartmentCustomerOptions: computed,
            getVehicleCustomerOptions: computed,
            getTruckCustomerOptions: computed,
            getCustomerPaymentReportOptions: computed
        })
    }

    GetAllClientsService = (isDahboard: boolean = false) => {
        this.inProgress = true;
        const url = UrlConstants.GetAllClientAdminNames;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.clientList = response.data.Data.ClientNameList;
                let clietIdFromLocalStorage = localStorage.getItem("secure_fuel_clientId");
                // let amountDecimalFromLocalStorage = localStorage.getItem("secure_fuel_amount_decimal");
                this.selectedClientId = clietIdFromLocalStorage !== null ? parseInt(clietIdFromLocalStorage) : -1;
                if (this.selectedClientId == -1) {
                    this.selectedClientId = this.clientList.length > 0 ? this.clientList[0].ClientId : -1;
                    let amountDecimalValue = this.clientList.length > 0 ? (this.clientList[0].AmountDecimal !== 0 ? 3 : 2) : 2;
                    localStorage.setItem("secure_fuel_clientId", this.selectedClientId.toString());
                    localStorage.setItem("secure_fuel_amount_decimal", amountDecimalValue.toString());
                }
                else {
                    let selectedClientIdPresent = this.clientList.length > 0 ? this.clientList.filter((client: any) => client.ClientId == this.selectedClientId) : [];
                    if (selectedClientIdPresent.length == 0) {
                        this.selectedClientId = this.clientList.length > 0 ? this.clientList[0].ClientId : -1;
                        let amountDecimalValue = this.clientList.length > 0 ? (this.clientList[0].AmountDecimal !== 0 ? 3 : 2) : 2;
                        localStorage.setItem("secure_fuel_clientId", this.selectedClientId.toString());
                        localStorage.setItem("secure_fuel_amount_decimal", amountDecimalValue.toString());
                    }
                    else {
                        let selectedClient = this.clientList.filter((client: any) => client.ClientId == this.selectedClientId)[0];
                        let amountDecimalValue = selectedClient.ClientId > 0 ? (selectedClient.AmountDecimal !== 0 ? 3 : 2) : 2;
                        localStorage.setItem('secure_fuel_amount_decimal', amountDecimalValue.toString())
                        // this.amountDecimalValue = decimalValue ? decimalValue : 0;
                    }
                }

                if (this.selectedSuperAdminDashboardClientName == "please_select") {
                    let tempSelectedSuperAdminDashboardClientName = localStorage.getItem("secure_fuel_superadmin_dashboard_filter");
                    if (this.clientList && this.clientList.length > 0) {
                        if (tempSelectedSuperAdminDashboardClientName != null)
                            this.setSelectedSuperAdminDashboardClientName(tempSelectedSuperAdminDashboardClientName!);
                        else
                            this.setSelectedSuperAdminDashboardClientName("All");
                    }
                }
                else if (this.clientList.map(e => e.Name).includes(this.selectedSuperAdminDashboardClientName)) {
                    this.setSelectedSuperAdminDashboardClientName(this.selectedSuperAdminDashboardClientName);
                } else {
                    this.setSelectedSuperAdminDashboardClientName("All");
                }


            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get allAvailableAccountOptions(): IOption[] {
        const clientOptions: IOption[] = [
            {
                Id: -2,
                Name: "please_select",
            },
            {
                Id: -1,
                Name: "All",
            }
        ];
        if (this.clientList && this.clientList?.length > 0)
            this.clientList.map((client) => {
                clientOptions.push({
                    Id: client.ClientId,
                    Name: client.Name,
                })
            })
        return clientOptions;
    }

    get allAvailableClientOptions(): IOption[] {
        const clientOptions: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            },
        ];
        if (this.clientList && this.clientList?.length > 0)
            this.clientList.map((client) => {
                clientOptions.push({
                    Id: client.ClientId,
                    Name: client.Name,
                })
            })
        return clientOptions;
    }

    get allAvailableClientsOptions(): IOption[] {
        let clientOptions: IOption[] = []
        if (this.clientList && this.clientList?.length > 0) {
            this.clientList.map((client) => {
                clientOptions.push({
                    Id: client.ClientId,
                    Name: client.Name,
                })
            })
        }
        else {
            clientOptions = [];
        }
        return clientOptions;
    }

    resetClientListState = () => {
        this.clientList = [];
    }

    get tableViewData() {
        return this.tableView;
    }

    setUserPageLimitSetting = (key: string, pageLimit: number) => {
        this.userPageLimitSetting[key] = pageLimit;
    }

    setSelectedClientId = (id: number) => {
        localStorage.setItem('secure_fuel_clientId', id.toString());
        this.selectedClientId = id;
    }

    setAmountDecimal = (clientId: number) => {
        let selectedClient = this.clientList.filter((client: any) => client.ClientId == clientId)[0]
        let amountDecimalValue = selectedClient ? (selectedClient.AmountDecimal !== 0 ? 3 : 2) : 2;
        localStorage.setItem('secure_fuel_amount_decimal', amountDecimalValue.toString());
    }

    setSelectedSuperAdminDashboardClientName = (name: string) => {
        localStorage.setItem('secure_fuel_superadmin_dashboard_filter', name)
        this.selectedSuperAdminDashboardClientName = name;
    }

    setUpdateTableView = (tableView: string) => {
        this.tableView = tableView;
        localStorage.setItem('secure_fuel_tableView', tableView)
    }

    /**
    * This function is used to check if the user already Exists or not by calling an API.
    * @param email : Login ID
    * @returns 
    */
    VerifyUserPasswordResetService = (email: string) => {
        this.isUserExistsState.inProgress = true;
        // let data = { Email:email }
        let url = `${UrlConstants.VerfiyEmail}/${email}`;
        // if(email==="aashish.singh@meritech.co.in")
        // {
        //     this.isUserExists = true;
        //     this.isUserExistsState.success = true;
        // }
        // else{
        //     this.isUserExistsState.error = "not_found";
        // }
        // this.isUserExistsState.inProgress = false;

        return services.post(url, {})
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                // this.isUserExists = response.data.Data;
                this.isUserExists = true;
                this.isUserExistsState.success = true;
            })
            .catch((err: string) => {
                this.isUserExistsState.error = err;
            })
            .finally(action(() => { this.isUserExistsState.inProgress = false; }));
    }

    /*
    This function is used to reset all UserCheck observables to their initial values.  
    */
    resetIsUserCheck = () => {
        this.isUserExistsState = { ...this.initialStateValue };
    }

    /**
     * This function is used to verify the received OTP to reset Password by calling an API & sending email & otp.
     * @param email : User Email / Login ID
     * @param otp : OTP received on Email
     * @returns 
     */
    VerifyOTPPasswordResetService = (email: string, otp: number) => {
        this.isOTPVerifiedState.inProgress = true;
        // let data = { Email:email, OneTimePassword: otp };
        let url = `${UrlConstants.VerfiyOtp}/${otp}/${email}`;
        // if(otp == 178623)
        // {
        //     this.isOTPVerified = true;
        //     this.oneTimePassword = otp;
        //     this.isOTPVerifiedState.success = true;
        // }
        // else{
        //     this.isOTPVerifiedState.error = "not_found";
        // }
        // this.isOTPVerifiedState.inProgress = false;

        return services.post(url, {})
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.isOTPVerified = true;//response.data?.Data.IsOTPVerified;
                this.oneTimePassword = otp;
                this.isOTPVerifiedState.success = true;
            })
            .catch((err: string) => {
                this.isOTPVerifiedState.error = err;
            })
            .finally(action(() => { this.isOTPVerifiedState.inProgress = false; }));
    }

    /*
    This function is used to reset isOTPVerifiedState observable to their initial values.  
    */
    resetIsOTOVerified = () => {
        this.isOTPVerifiedState = { ...this.initialStateValue };
    }

    /**
     * This function reset existing password with the new password by calling an API. 
     * @param data : password reset details
     * @returns 
     */
    UpdateUserPasswordByOTPService = (data: IChangePasswordState) => {
        this.isPasswordUpdatedState.inProgress = true;
        let url = `${UrlConstants.ChangePassword}/${data.Email}`;
        let requestBody = {
            Password: data.NewPassword,
            RepeatPassword: data.RepeatPassword
        }
        return services.post(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                this.isPasswordUpdatedState.success = true;
            })
            .catch((err: string) => {
                this.isPasswordUpdatedState.error = err;
            })
            .finally(action(() => { this.isPasswordUpdatedState.inProgress = false; }));
    }

    GetTruckSummaryService = () => {
        this.truckSummaryState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetTruckSummary}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: AxiosResponse<any>) => {
                this.truckSummaryState.success = true;
                this.truckSummaryDetails = response.data.Data;
            })
            .catch((err: string) => {
                // this.truckSummaryState.error = err;
                toast.error(formatMessage(err));
            }).finally(action(() => { this.truckSummaryState.inProgress = false; }));
    }


    GetVehicleSummaryService = () => {
        this.vehicleSummaryState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetVehicleSummary}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: AxiosResponse<any>) => {
                this.vehicleSummaryState.success = true;
                this.vehicleSummaryDetails = response.data.Data;
            })
            .catch((err: string) => {
                // this.vehicleSummaryState.error = err;
                toast.error(formatMessage(err));
            }).finally(action(() => { this.vehicleSummaryState.inProgress = false; }));
    }

    GetCustomerSummaryService = () => {
        this.customerSummaryState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetCustomerSummary}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: AxiosResponse<any>) => {
                this.customerSummaryState.success = true;
                this.customerSummaryDetails = response.data.Data;
            })
            .catch((err: string) => {
                // this.customerSummaryState.error = err;
                toast.error(formatMessage(err));
            }).finally(action(() => { this.customerSummaryState.inProgress = false; }));
    }

    GetDepartmentSummaryService = () => {
        this.departmentSummaryState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetDepartmentSummary}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: AxiosResponse<any>) => {
                this.departmentSummaryState.success = true;
                this.departmentSummaryDetails = response.data.Data;
            })
            .catch((err: string) => {
                // this.departmentSummaryState.error = err;
                toast.error(formatMessage(err));
            }).finally(action(() => { this.departmentSummaryState.inProgress = false; }));
    }

    resetSummaryState = () => {
        this.departmentSummaryState = { ...this.initialStateValue };
        this.customerSummaryState = { ...this.initialStateValue };
        this.vehicleSummaryState = { ...this.initialStateValue };
        this.truckSummaryState = { ...this.initialStateValue };
    }

    resetUpdatePasswordState = () => {
        this.isPasswordUpdatedState = { ...this.initialStateValue };
    }

    get getTruckOptions() {
        const options: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            }
        ];
        if (this.truckSummaryDetails && this.truckSummaryDetails?.length > 0)
            this.truckSummaryDetails.map((detail) => {
                options.push({
                    Id: detail.Id,
                    Name: detail.Name
                })
            })
        return options;
    }

    get getTruckCustomerOptions() {
        const options: { Id: string, Name: string }[] = [{
            Id: "please_select",
            Name: "please_select",
        }]
        if (this.truckSummaryDetails && this.truckSummaryDetails?.length > 0)
            this.truckSummaryDetails.map((detail) => {
                options.push({
                    Id: detail.Name,
                    Name: detail.Name
                })
            })
        return options;
    }

    get getCustomerOptions() {
        let customerId = getCustomerId() ? Number(getCustomerId()) : -1
        const options: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            }
        ];
        if (getUserType() === UserType.CustomerAdmin) {
            if (this.customerSummaryDetails && this.customerSummaryDetails?.length > 0)
                this.customerSummaryDetails.filter((e) => e.Id === customerId).map((detail) => {
                    options.push({
                        Id: detail.Id,
                        Name: detail.Name
                    })
                })
        } else {
            if (this.customerSummaryDetails && this.customerSummaryDetails?.length > 0)
                this.customerSummaryDetails.map((detail) => {
                    options.push({
                        Id: detail.Id,
                        Name: detail.Name
                    })
                })
        }


        return options;
    }

    get getCustomerPaymentReportOptions() {
        let customerId = getCustomerId() ? Number(getCustomerId()) : -1
        const options: IOption[] = [
            {
                Id: -1,
                Name: "All",
            }
        ];
        if (getUserType() === UserType.CustomerAdmin) {
            if (this.customerSummaryDetails && this.customerSummaryDetails?.length > 0)
                this.customerSummaryDetails.filter((e) => e.Id === customerId).map((detail) => {
                    options.push({
                        Id: detail.Id,
                        Name: detail.Name
                    })
                })
        } else {
            if (this.customerSummaryDetails && this.customerSummaryDetails?.length > 0)
                this.customerSummaryDetails.map((detail) => {
                    options.push({
                        Id: detail.Id,
                        Name: detail.Name
                    })
                })
        }


        return options;
    }

    get getVehicleOptions() {
        const options: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            }
        ];
        if (this.vehicleSummaryDetails && this.vehicleSummaryDetails?.length > 0)
            this.vehicleSummaryDetails.map((detail) => {
                if (detail.Name) {
                    options.push({
                        Id: detail.Id,
                        Name: detail.Name
                    })
                }
            })
        return options;
    }

    get getVehicleCustomerOptions() {
        const options = [
            {
                Id: "All",
                Name: "All",
            }
        ];
        if (this.vehicleSummaryDetails && this.vehicleSummaryDetails?.length > 0)
            this.vehicleSummaryDetails.map((detail) => {
                if (detail.Name) {
                    options.push({
                        Id: detail.Name,
                        Name: detail.Name
                    })
                }
            })
        return options;
    }

    get getDepartmentOptions() {
        const options: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            }
        ];
        if (this.departmentSummaryDetails && this.departmentSummaryDetails?.length > 0)
            this.departmentSummaryDetails.map((detail) => {
                if (detail.Name && detail.Name.trim())
                    options.push({
                        Id: detail.Id,
                        Name: detail.Name
                    })
            })
        return options;
    }
    get getDepartmentCustomerOptions() {
        const options = [
            {
                Id: "All",
                Name: "All",
            }
        ];
        if (this.departmentSummaryDetails && this.departmentSummaryDetails?.length > 0)
            this.departmentSummaryDetails.map((detail) => {
                if (detail.Name && detail.Name.trim())
                    options.push({
                        Id: detail.Name,
                        Name: detail.Name
                    })
            })
        return options;
    }

    /*
    This function is used to download CSVFile.  
    we can use this code when we get the csv data from API
    */
    CSVFileDownloadService = (url: string, title: string) => {
        const token = getToken();
        this.csvDownloadState.inProgress = true;
        axiosDownload({
            url: url,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'authorization': 'Bearer ' + token
            }
        }).then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title + '_' + moment(Date.now()).format(DATE_TIME_FORMAT_FILENAME) + '.csv');
            document.body.appendChild(link);
            link.click();
            toast.success(formatMessage("downloaded_started_successfully"));
        }).catch((err: any) => {
            toast.error(formatMessage(ErrorMessage.ErrorOccured));
        })
            .finally(action(() => { this.csvDownloadState.inProgress = false; }));
    }

    /*
    This function is used to download CSVFile by post requerst method.  
    we can use this code when we get the csv data from API
    */
    CSVFileDownloadPostService = (url: string, title: string, downloadData: any) => {
        const token = getToken();
        this.csvDownloadState.inProgress = true;
        axiosDownload({
            url: url,
            method: 'POST',
            responseType: 'blob',
            headers: {
                'authorization': 'Bearer ' + token
            },
            data: downloadData
        }).then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title + '_' + moment(Date.now()).format(DATE_TIME_FORMAT_FILENAME) + '.csv');
            document.body.appendChild(link);
            link.click();
            toast.success(formatMessage("downloaded_started_successfully"));
        }).catch((err: any) => {
            toast.error(formatMessage(ErrorMessage.ErrorOccured));
        })
            .finally(action(() => { this.csvDownloadState.inProgress = false; }));
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.tableView = localStorage.getItem('secure_fuel_tableView') !== null ? localStorage.getItem('secure_fuel_tableView') : 'false';
        this.userPageLimitSetting = {
            trasnactionLimitConfig: 25,
            vehicleLimitConfig: 25,
            driverLimitConfig: 25,
            tankLimitConfig: 25,
            alarmLimitConfig: 25,
            roleLimitConfig: 25,
            clientLimitConfig: 25,
        };
        this.selectedClientId = -1;
        this.clientList = [];
        this.isUserExistsState = { ...this.initialStateValue };

        this.isUserExists = false;

        this.isOTPVerifiedState = { ...this.initialStateValue }
        this.isOTPVerified = false;
        this.oneTimePassword = undefined;

        this.isPasswordUpdatedState = { ...this.initialStateValue }
    }
}

export default new PreferencesStore();