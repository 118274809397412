import { jwtDecode as jwt } from 'jwt-decode';
import UserType from "../constants/userType.constant";
import authStore from "../core/stores/auth-store";
import { formatMessage } from "../translations/formatMessage";
import { getToken, getParamType, getUserType, getClientLimitSetting } from "./localStorages.helper";
import { permissionType } from "../constants/enums/permissionTypeEnum";
import config from "./config.helper";
import RoutesConstants from '../constants/routes.constant';
import { ModuleName } from '../constants/moduleName.constant';
import { Navigate } from 'react-router-dom';

interface IPermittedRoute {
  moduleName: string;
  route: string;
}

const appConfig = config();

const findModuleActionAccess = (moduleName: string) => {
  let userPermission: any = authStore.getAllUserPermission;
  if (JSON.stringify(userPermission) === "{}") {
    const permissions = localStorage.getItem('secure_fuel_userPermissions');
    if (permissions) {
      userPermission = JSON.parse(permissions);
    }
  }
  for (let key in userPermission) {
    if (key == moduleName)
      return userPermission[moduleName] == permissionType.READ_WRITE;
  }
  return true;
}

// const checkPermission = (moduleName: string) => {
//   // const module = findModule(moduleName);

//   if(module === undefined)
//     return false;
//   return module?.Type === PermissionType.Full || module?.Type === PermissionType.Readonly;
// }

// export const isPermitted = (moduleName: string) => { //moduleName = MenuItem_Users
//   const userType = authStore.userType ? authStore.userType : localStorage.getItem("secure_fuel_userType");
//   if(userType === UserType.SuperAdmin || userType === UserType.CustomerUser){
//     return true;
//   }
//   return checkPermission(moduleName);
// }

// export const permittedRoute = (arr: Array<IPermittedRoute>) => {
//   let route = RoutesConstants.Home;
//   for(let i of arr){
//     if(isPermitted(i.moduleName)){
//       route = i.route;
//       break;
//     }
//   }
//   return route;
// }

export const checkRedirectCondition = () => {
  const userIsCustomType = (authStore.userType === UserType.CustomerUser || getUserType() === UserType.CustomerUser);
  // let route = RoutesConstants.User; 
  let route = RoutesConstants.Dashboard;
  if (userIsCustomType) {
    // const hasAccess = isPermitted(ModuleName.MenuItemPrivilege);
    // route = hasAccess ? permittedRoute(PrivilegeRoutes) : RoutesConstants.Dashboard;
    route = RoutesConstants.Dashboard;
  }
  if (!authStore.isAuthenticated && !getToken()) {
    route = RoutesConstants.Login;
  }
  return <Navigate to={route} />;
}

export const isOperationPermitted = (moduleName: string) => {
  const userType = localStorage.getItem('secure_fuel_userType');

  if (userType === UserType.SuperAdmin || userType === UserType.CleintAdmin)
    return true;
  if (userType === UserType.CustomerUser) return false;
  if (userType === UserType.CustomerAdmin) return (moduleName == ModuleName.User || moduleName == ModuleName.Customer) ? true : false;
  const actionAccess = findModuleActionAccess(moduleName);

  return actionAccess;
}

export const showIcons = <T extends { title: string }>(AddIcons: Array<T>, moduleName: string) => {
  let userType = authStore.userType ? authStore.userType : localStorage.getItem("secure_fuel_userType");
  let icons;
  if (!isOperationPermitted(moduleName)) {
    let iconEdit = AddIcons.filter((icon => (icon.title !== 'label_edit')));
    icons = iconEdit.filter(icon => (icon.title !== 'label_delete'));
  }
  else if (!isOperationPermitted(moduleName)) {
    icons = AddIcons.filter(icon => icon.title === 'label_showdetails');
  }
  else {
    if (userType === UserType.SuperAdmin && moduleName === ModuleName.TicketManagement) {
      icons = AddIcons;
    }
    else if (userType !== UserType.SuperAdmin && moduleName === ModuleName.TicketManagement) {
      icons = AddIcons.filter(icon => (icon.title !== 'label_delete'));
    }
    else {
      icons = AddIcons;
    }
  }
  return icons;
}

// redirect the user to request route based on token and route params name.
export const redirectToRequestedRoute = () => {
  const paramType = getParamType();
  const token = getToken();
  // let userInParams = appConfig.PUBLIC_URL;
  let userInParams = 'http://localhost:7003';
  if (token && paramType) {
    const userToken: any = jwt(token);
    const userIsSystemAdmin = userToken.userType === UserType.SuperAdmin;
    if (!userIsSystemAdmin)
      userInParams = paramType;
  }
  return userInParams;
}

// redirect the user to defined route based on token and route params name.
export const redirectToDefinedRoute = () => {
  const paramType = getParamType();
  const token = getToken();
  // let userInParams = '/' + window.location.pathname.split('/')[1];
  let userInParams = 'http://localhost:7003';
  if (token && paramType) {
    const userToken: any = jwt(token);
    const userIsSystemAdmin = userToken.userType === UserType.SuperAdmin;
    if (!userIsSystemAdmin)
      userInParams = paramType;
  }
  return userInParams;
}

const getModuleArrayByUserType = (userType: string) => {
  const superAdminPermittedRoutes = [ModuleName.Supplier, ModuleName.User, ModuleName.Clients, ModuleName.Customer, ModuleName.Roles, ModuleName.AddTransaction, ModuleName.SuperAdminDashboard, ModuleName.TankManagement, ModuleName.TicketManagement];
  const clientAdminPermittedRoutes = [ModuleName.Supplier, ModuleName.Dashboard, ModuleName.Alarms, ModuleName.TicketManagement, ModuleName.DriverManagement, ModuleName.VehicleManagement, ModuleName.LiveTracking, ModuleName.User, ModuleName.Customer, ModuleName.Reports, ModuleName.Roles];
  const clientUserPermittedRoutes = [ModuleName.Dashboard, ModuleName.TicketManagement, ModuleName.DriverManagement, ModuleName.VehicleManagement, ModuleName.Alarms, ModuleName.LiveTracking, ModuleName.Reports];
  const customerAdminPermittedRoutes = [ModuleName.CustomerDashboard, ModuleName.Alarms, ModuleName.TicketManagement, ModuleName.DriverManagement, ModuleName.VehicleManagement, ModuleName.User, ModuleName.Reports];
  const customerUserPermittedRoutes = [ModuleName.Alarms, ModuleName.TicketManagement, ModuleName.DriverManagement, ModuleName.VehicleManagement];
  switch (userType) {
    case UserType.SuperAdmin:
      return superAdminPermittedRoutes;
    case UserType.CleintAdmin:
      return clientAdminPermittedRoutes;
    case UserType.ClientUser:
      return clientUserPermittedRoutes;
    case UserType.CustomerAdmin:
      return customerAdminPermittedRoutes;
    case UserType.CustomerUser:
      return customerUserPermittedRoutes;
    default:
      return customerUserPermittedRoutes;
  }
}
export const isRoutePermitted = (moduleName: string) => {
  const userType = authStore.userType ? authStore.userType : localStorage.getItem("secure_fuel_userType");
  let clientSetting = getClientLimitSetting();
  let tempModulesArray = getModuleArrayByUserType(userType!);
  if (!clientSetting.EnableGpsSettings) {
    tempModulesArray = tempModulesArray.filter((module: string) => module != ModuleName.LiveTracking);
  }
  if (!clientSetting.EnableAlarmSettings) {
    // tempModulesArray = tempModulesArray.filter((module:string)=> (module != ModuleName.TicketManagement));
    tempModulesArray = tempModulesArray.filter((module: string) => (module != ModuleName.Alarms));
  }
  switch (userType) {
    case UserType.SuperAdmin:
      return tempModulesArray.includes(moduleName) ? true : false;
    case UserType.CleintAdmin:
      return tempModulesArray.includes(moduleName) ? true : false;
    case UserType.CustomerAdmin:
      return tempModulesArray.includes(moduleName) ? true : false;
    case UserType.CustomerUser:
      return tempModulesArray.includes(moduleName) ? true : false;
    case UserType.ClientUser:
      let userPermission: any = authStore.getAllUserPermission;
      if (JSON.stringify(userPermission) === "{}") {
        const permissions = localStorage.getItem('secure_fuel_userPermissions');
        if (permissions)
          userPermission = JSON.parse(permissions);
      }
      let tempClientModuleList: string[] = [...tempModulesArray];
      // if(!clientSetting.EnableGpsSettings)
      //   tempClientModuleList = tempClientModuleList.filter((module:string)=> module != ModuleName.LiveTracking);
      // if(!clientSetting.EnableAlarmSettings){
      //   // tempClientModuleList = tempClientModuleList.filter((module:string)=> (module != ModuleName.TicketManagement));
      //   tempClientModuleList = tempClientModuleList.filter((module:string)=> (module != ModuleName.Alarms));
      // }
      for (let key in userPermission) {
        if (key == moduleName && userPermission[moduleName] == permissionType.NONE)
          tempClientModuleList = tempClientModuleList.filter((module: string) => module != moduleName);
      }
      return tempClientModuleList.includes(moduleName) ? true : false;
  }
  if (userType == null)
    return true;
}

export const redirectToDefaultRoute = () => {
  let userType = authStore.userType ? authStore.userType : localStorage.getItem("secure_fuel_userType");
  if (userType == UserType.CustomerAdmin || userType == UserType.CustomerUser)
    return RoutesConstants.Vehicles;
  else if (userType == UserType.SuperAdmin)
    return RoutesConstants.SuperAdminDashboard;
  else
    return RoutesConstants.Dashboard;
}
// export const checkIsPermitted  = (moduleName: string) =>{
//   const userType = localStorage.getItem('secure_fuel_userType');
//   if(userType === UserType.SuperAdmin || userType === UserType.CleintAdmin)
//     return true;
//   if(userType === UserType.CustomerUser || userType === UserType.CustomerAdmin)
//       return false;
//   let userPermission: any = authStore.getAllUserPermission;
//   if(JSON.stringify(userPermission) === "{}") {
//     const permissions = localStorage.getItem('secure_fuel_userPermissions');
//     if(permissions) {
//         userPermission = JSON.parse(permissions);
//     }
//   }
//   for(let key in userPermission)
//   {
//     if(key == moduleName)
//     {
//       return userPermission[moduleName] == permissionType.READ_WRITE;
//     }
//   }
//   return true;
// }
