/*    
<summary>
   This is the Truck Component which shows the Trucks Data.
   Developer: Aashish Singh, Created Date:04-mar-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, {
  getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SearchField from "../../shared-components/SearchField/SearchField";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter, IFormatter } from "../../models/IFormatter";
import {
  PageLimitOptions,
  initialPageLimit,
} from "../../constants/pageLimit.constant";
import { Button, Container, Dropdown } from "react-bootstrap";
import DeletePopup from "../../shared-components/Popup/DeletePopup";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import { Col, ListGroup, Row } from "react-bootstrap";
import { ITruck, ITruckList } from "../../models/response/ITruckResponse";
import "./Tanks.scss";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import SlidingPanel from "react-sliding-side-panel";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import { isOperationPermitted, showIcons, } from "../../helpers/routesFunctions.helper";
import { getClientId, getTableView, getUserType } from "../../helpers/localStorages.helper";
import { ModuleName } from "../../constants/moduleName.constant";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import NoData from "../../assets/Images/no_record.png";
import _, { filter } from "lodash";
import AddUpdateTruck from "./component/add-edit-trucks";
import TruckFilterForm from "./forms/truck-filter-form";
import { truckInitialFilterState } from "../../core/initialState/initial-filter-states";
import userPageLimitSettingEnum from "../../constants/enums/user-pagination-setting-enum";
import UserType from "../../constants/userType.constant";


interface IProps {
  tabKey: string;
  setTabKey: (k: string) => void;
  size: {
    height: number;
  };
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;

}

const Truck: React.FC<IProps> = (props: IProps) => {
  const { truckStore, preferencesStore, customerStore } = useStore();
  const {
    tabKey,
    setTabKey,
    showDeletePopup,
    deleteToggleHandler,
    showAddPopup,
    addToggleHandler,
    showFilterPopup,
    filterToggleHandler,
  } = props;
  const {
    inProgress,
    error,
    truckList,
    GetTruckListService,
    getTruckList,
    reset,
    resetError,
    DeleteTruckService,
    DeleteTruckState,
    resetDeleteTruckState,
    addUpdateTruckState,
    resetGetTruckDetail,
    setFilterDetail,
    filter
  } = truckStore;
  const { setUpdateTableView, CSVFileDownloadPostService, userPageLimitSetting, setAmountDecimal, setUserPageLimitSetting, setSelectedClientId, selectedClientId, allAvailableClientOptions, resetClientListState, clientList } = preferencesStore;
  const { GetAllCustomerService } = customerStore;
  const [tableView, setTableView] = useState<boolean>(
    getTableView() ? (getTableView() == "false" ? false : true) : false
  );
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedRow, setSelectedRow] = useState<ITruckList | undefined>(
    undefined
  );
  const [openPanel, setOpenPanel] = useState(false);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const truckListData: Array<ITruckList> = getTruckList;
  const [pageLimit, setPageLimit] = useState<number>(userPageLimitSetting.tankLimitConfig);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "truck_id",
    orderBy: "asc",
  });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForUser = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimit(value);
    setUserPageLimitSetting(userPageLimitSettingEnum.TankLimitConfig, value)
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  // const exportFilteredLogsCSV = () => {
  //   let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
  //   const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
  //   const url = `${UrlConstants.TruckCSVDownload}?clientId=${clientIdFromLocalStorage}`;
  //   tempFilterDetails = {
  //     ...tempFilterDetails,
  //     DLExpiryInDays:
  //       tempFilterDetails.DLExpiryInDays == -1
  //         ? null
  //         : tempFilterDetails.DLExpiryInDays,
  //   };
  //   CSVFileDownloadPostService(url, "Truck", tempFilterDetails);
  // };

  /**
   * This function calls the "GetTruckListService" store funtion that get truck list from API
   */
  const callGetTruckList = useCallback(() => {
    setDisablePagination(true);

    if (getUserType() === UserType.SuperAdmin) {
      if (selectedClientId > 0 && clientList.length > 0) {
        GetTruckListService(
          pageInfo.currentPage,
          pageLimit,
          sorting.orderBy,
          sorting.column,
          _.omit(filter, ["isFilterActive"])
        );
      }
    }
    else {
      GetTruckListService(
        pageInfo.currentPage,
        pageLimit,
        sorting.orderBy,
        sorting.column,
        _.omit(filter, ["isFilterActive"])
      );
    }
    setIsApiCalled(true);
  }, [
    GetTruckListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    filter,
    selectedClientId,
    clientList.length
  ]);


  useEffect(() => {
    return () => {
      resetFilterHandler();
      resetClientListState();
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "filter" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (selectedClientId > 0 && filter.isFilterActive) {
  //     if (getUserType() == UserType.SuperAdmin) {
  //       if (selectedClientId != -1) callGetTruckList();
  //       else return;
  //     } else callGetTruckList();
  //   }
  //   else {
  //     if (getUserType() == UserType.SuperAdmin) {
  //       if (selectedClientId != -1) callGetTruckList();
  //       else return;
  //     } else callGetTruckList();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filter, selectedClientId, callGetTruckList]);

  useEffect(() => {
    if (selectedClientId > 0)
      callGetTruckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId, callGetTruckList]);

  const [selectedCard, setSelectedCard] = useState<number>(-1);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: truckList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getTruckList, truckList.TotalPages]);

  useEffect(() => {
    if (addUpdateTruckState.success) {
      callGetTruckList();
    }
  }, [addUpdateTruckState.success, callGetTruckList]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  useEffect(() => {
    if (DeleteTruckState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteTruckState();
      deleteToggleHandler();
      callGetTruckList();
    }
  }, [DeleteTruckState.success, reset, callGetTruckList]);

  useEffect(() => {
    if (DeleteTruckState.error) {
      toast.error(formatMessage(DeleteTruckState.error));
      resetDeleteTruckState();
    }
  }, [DeleteTruckState.error, resetDeleteTruckState]);

  /**
   * This function shows the add truck pop up from where we can add new truck and update existing truck. we use id to identify
   * that we are adding or updating truck.
   * "Id == -1" : we are adding new truck
   * "Id != -1" : means we are updating existing truck
   */
  const openAddUpdateHandler = (row: { Id: number }) => {
    GetAllCustomerService();
    setSelectedId(row.Id);
    setChangeDetails(false);
    addToggleHandler();
  };

  /**
   * This function shows the delete truck confirmation popup component and sets the seleted truck Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(false);
    deleteToggleHandler();
  };

  /**
   * This function deletes the truck by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteTruckService(selectedId);
  };

  /**
   * This function shows the detail truck panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const rowEvents = (row: any) => {
    setSelectedCard(row.TruckId)
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const tableViewToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tableView =
      localStorage.getItem("secure_fuel_tableView") == "false"
        ? "true"
        : "false";
    setUpdateTableView(tableView);
    if (tableView == "false") {
      setTableView(false);
    } else {
      setTableView(true);
    }
  };


  const openFilterHandler = () => {
    // closePanelHandler();
    filterToggleHandler();
  };

  /**
   * This function provides filtering functionality of data on the basis of client id
   */
  const clientSelectHandler = (e: { label: string; value: number; key: number }) => {
    let value = e.value;
    if (value < 0) toast.error(formatMessage("please_select_any_client_admin"));
    else {
      resetFilterHandler();
      setSelectedClientId(value);
      setPageInfo({ ...pageInfo, currentPage: 1 });
      setAmountDecimal(value)
    }
  };

  const filterSubmitHandler = (values: any) => {
    // closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...values, isFilterActive: true });
    filterToggleHandler();
  };

  const resetFilterHandler = () => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...truckInitialFilterState });
  };

  const exportFilteredLogsCSV = () => {
    // let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    // tempFilterDetails = { ...tempFilterDetails, RegistrationExpiryDateInDays: tempFilterDetails.RegistrationExpiryDateInDays == -1 ? null : tempFilterDetails.RegistrationExpiryDateInDays };
    // const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    // const url = `${UrlConstants.VehicleCSVDownload}?clientId=${clientIdFromLocalStorage}`;
    // CSVFileDownloadPostService(url, "Vehicle", tempFilterDetails);
  };

  const addIcons: Array<IActionFormatter<string>> = [
    {
      handler: openAddUpdateHandler,
      icon: (
        <svg height="14" viewBox="0 0 492.49284 492" width="14">
          <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
          <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
        </svg>
      ),
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning",
    },
    {
      handler: deleteClickHandler,
      icon: <svg
        version="1.1"
        x="0px"
        y="0px"
        width="13"
        height="13"
        viewBox="0 0 512 512"
      >
        <g>
          <g>
            <path
              d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>,
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
    },
  ];

  const columns = getColumns<ITruck, number>(
    showIcons(addIcons, ModuleName.TankManagement).length > 0 ?
      [
        {
          columnType: ColumnType.Hidden,
          accessorKey: "Id",
        },
        // {
        //   columnType: ColumnType.Sorting,
        //   accessorKey: "Name",
        //   sortingName: "truckname",
        //   displayName: "truckname",
        // },
        {
          columnType: ColumnType.Sorting,
          accessorKey: "TruckId",
          sortingName: "truck_id",
          displayName: "truck_id",
        },
        {
          columnType: ColumnType.ActionsFormatter,
          icons: showIcons(addIcons, ModuleName.TankManagement),
          displayName: "label_action",
        },
        {
          columnType: ColumnType.Sorting,
          accessorKey: "DeviceId",
          sortingName: "device_id",
          displayName: "transactions_label_deviceid",
        },
        // {
        //   columnType: ColumnType.Sorting,
        //   accessorKey: "ClientId",
        //   sortingName: "client_id",
        //   displayName: "client_id",
        // },
        {
          columnType: ColumnType.TextHeader,
          accessorKey: "Compartment",
          displayName: "compartment",
        },
      ] : [
        {
          columnType: ColumnType.Hidden,
          accessorKey: "Id",
        },
        // {
        //   columnType: ColumnType.Sorting,
        //   accessorKey: "Name",
        //   sortingName: "truckname",
        //   displayName: "truckname",
        // },
        {
          columnType: ColumnType.Sorting,
          accessorKey: "TruckId",
          sortingName: "truck_id",
          displayName: "truck_id",
        },
        {
          columnType: ColumnType.Sorting,
          accessorKey: "DeviceId",
          sortingName: "device_id",
          displayName: "transactions_label_deviceid",
        },
        // {
        //   columnType: ColumnType.Sorting,
        //   accessorKey: "ClientId",
        //   sortingName: "client_id",
        //   displayName: "client_id",
        // },
        {
          columnType: ColumnType.TextHeader,
          accessorKey: "Compartment",
          displayName: "compartment",
        },
      ],
    sorting,
    handleSortingChange
  );

  return (
    <React.Fragment>
      {showAddPopup && (
        <AddUpdateTruck id={selectedId} modalClosed={addToggleHandler} />
      )}
      {showDeletePopup && (
        <DeletePopup
          modalSubmit={deleteHandler}
          modalClosed={deleteToggleHandler}
          isLoading={DeleteTruckState.inProgress}
          message="delete_truck_confirm"
          submitBtnText="submit_button"
          cancelBtnText="button_cancel"
          modalTitle="delete_truck"
        />
      )}

      {showFilterPopup && (
        <TruckFilterForm
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={DeleteTruckState.inProgress}
          initialValues={_.omit(filter, ["isFilterActive"])}
        />
      )}

      {/* {showFilterPopup && (
        <TruckFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )} */}

      {/* <div className={`main-content user ${showUserDetail ? 'table-container' : ''} `}> */}
      <div className={`main-content user`}>
        <SubHeader
          moduleNameString={"trucks_title"}
          moduleName={ModuleName.TankManagement}
          addHandler={openAddUpdateHandler}
          isAdd={true}
          addButtonTitle="add_truck"
          filterHandler={openFilterHandler}
          isFilterActive={filter.isFilterActive}
          // exportCSVHandler={exportFilteredLogsCSV}
          showClientSelectHandler={true}
          clientSelectHandler={clientSelectHandler}
          resetFilterHandler={resetFilterHandler}
          isFullView={false}
          isDownload={false}
          isRefresh={false}
          isFilter={true}
          isDateFilter={false}
          isImport={false} />
      </div>

      {inProgress && <SpinnerLoader />}
      <Container fluid className="p-0">
        {truckListData.length !== 0 ? (
          <div className="truckWrapper" id="moveContent">
            <div className={`${openPanel ? "paneIsOpen" : ""}`}>
              <div className="pt-3 px-3">

                <div className="Total-users d-flex justify-content-end mb-2">
                  <FormattedMessage id="total_tanks" /> :{" "}
                  <b className="ps-1"> {truckList.TotalTrucks}</b>
                </div>
                <div className="Fuel-table">
                  <div
                    className="table-responsive"
                    style={getTableMaxHeight(height, width)}
                    data-testid="table"
                  >
                    <Grid
                      data={truckListData}
                      columns={columns}
                      isInProgress={inProgress}
                      hiddenColumns={["Id"]}
                    />
                  </div>
                </div>
                <Container fluid>
                  <Row className="tableFooter py-1">
                    <Col xs="3" className="d-flex">
                      <b className="text-white text-hidden-m mt-1 pt-1">
                        <FormattedMessage id="show" />:{" "}
                      </b>
                      <select
                        data-testid="pageSelect"
                        className="ms-2 from-control"
                        onChange={onPageLimitChangedForUser}
                        value={pageLimit}
                      >
                        {PageLimitOptions.map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Pagination
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div className="no_data_table background-image">
            <div className="no_data w-100 text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>

        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default FilterPopupHOC(
  EditPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Truck))))
);
